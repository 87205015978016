<template>
    <div class="monitor-track">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="设备IMEI" prop="imei">
                                <!-- <el-autocomplete  class="input-w290-h32" v-model="listQuery.imei" size="small" :fetch-suggestions="querySearch"
                                placeholder="请输入设备IMEI" :trigger-on-focus="false"></el-autocomplete> -->
                                <el-input class="input-w290-h32" :maxlength="15" v-model="listQuery.imei" placeholder="请输入设备IMEI"></el-input>
                            </el-form-item>
                            <el-form-item label="时间范围" prop="startTime"  class="clear-margin-right">
                                <el-date-picker class="date-w160-h32" align="right" size="small" v-model="listQuery.startTime" type="datetime" placeholder="请选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDate"></el-date-picker>
                                <span class="time-interval">至</span>
                            </el-form-item>
                            <el-form-item label="" prop="endTime">
                                <el-date-picker class="date-w160-h32" align="right" size="small" v-model="listQuery.endTime" type="datetime" :default-time="new Date()+''"
                                    placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerEndDate"></el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="includeSub">
                                <!-- <el-checkbox v-model="listQuery.includeSub">包含下级</el-checkbox> -->
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getTrackList"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="map-content">
            <div class="map-div">
                <div class="map" id="map"></div>
                <map-type @selectChangeMap="selectChangeMap" @selectChange="selectChange" ></map-type>
                <map-bottom @zoomOut="zoomOut" @zoomIn="zoomIn" @changeMapType="changeMapType"></map-bottom> 
                <div class="collapse">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <div class="tip">
                                    <span>设备信息</span>
                                    <svg-icon class="svg-icon icon_popup" icon-class="icon_popup" size="24"></svg-icon>
                                </div>
                            </template>
                            <div class="device-info">
                                <ul>
                                    <li>
                                        <p class="title">IMEI</p>
                                        <p class="content">{{deviceInfo.imei ? deviceInfo.imei : '--'}}</p>
                                    </li>
                                    <li>
                                        <p class="title">SIM卡号</p>
                                        <p class="content">{{ deviceInfo.sim ? deviceInfo.sim : '--' }}</p>
                                    </li>
                                    <li>
                                        <p class="title">设备型号</p>
                                        <p class="content">{{ deviceInfo.deviceModel ? deviceInfo.deviceModel:'--'}}</p>
                                    </li>
                                    <li>
                                        <p class="title">所属机构</p>
                                        <p class="content">{{ deviceInfo.orgName ? deviceInfo.orgName : '--'}}</p>
                                    </li>
                                    <li>
                                        <p class="title">里程</p>
                                        <p class="content">--</p>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="control-tool">
                    <div @click="playTrack()">
                        <svg-icon class="svg-icon" :icon-class="ifPlay ? 'icon-pause' : 'icon-play'" size="24"></svg-icon>
                    </div>
                    <div @click="pauseTrack()">
                        <svg-icon class="svg-icon icon-repaly" icon-class="icon-replay" size="24"></svg-icon>
                    </div>
                    <div class="speed-change">   
                        <el-dropdown @command="handleCommand" trigger="click">
                            <span class="speed">
                                {{playSpeed}}
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="x1">x1</el-dropdown-item>
                                <el-dropdown-item command="x2">x2</el-dropdown-item>
                                <el-dropdown-item command="x4">x4</el-dropdown-item>
                                <el-dropdown-item command="x8">x8</el-dropdown-item>
                                <el-dropdown-item command="x16">x16</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="progress">
                        <!-- <el-progress :percentage="50"></el-progress> -->
                        <div class="slider">   
                            <el-slider :show-tooltip="false" :disabled="trackPointArrayReturn.length <= 0" v-model="sliderIndex" @change="sliderChange"></el-slider>
                        </div>
                        <p class="slider-tip">{{sliderIndex}}%</p>
                    </div>
                    <div class="speed-tip">
                        速度: {{pointSpeed}}km/h
                    </div>
                </div>
            </div>
        </div>
        <div ref="trackInfo" class="windowInfo"> 
            <p> 经度: {{ windowInfo.lat}}</p>
            <p> 纬度: {{ windowInfo.lng}}</p>
            <p> 速度: {{ windowInfo.gpsSpeed}} km/h</p>
        </div>
    </div>
</template>
<script>
import GoogleMap from '../../components/map/googleMap';
import BaiduMap from '../../components/map/baiduMap';
import mapType from '../../components/map/changeMapType';
import mapBottom from '../../components/map/mapBottom';
import { gpsSelect,getDeviceByImei } from '@/api/track/index'
export default {
    components: {
        mapType,
        mapBottom
    },
    data() {
        return {
            listQuery:{
                imei:'',//设备imei
                startTime: '',//开始时间
                endTime: '',//结束时间
                includeSub:false,//包含下级
            },
            activeNames:[],
            pickerStartDate: {
                disabledDate: (time) => { // 限制开始时间小于结束时间
                    return time.getTime() > Date.now();
                }
            },
            pickerEndDate: {
                disabledDate: (time) => { // 限制开始时间小于结束时间
                    return time.getTime() > Date.now();
                }
            },
            map: null, //地图
            center: {//中心点
                lng: 116.3975, 
                lat: 39.9085
            },
            mapOpt: {//地图的基本配置
                zoomControl: true, 
            },
            playSpeed:'倍数',
            ifPlay:false,
            trackPointArrayReturn:[],//所有轨迹点
            deviceInfo:{
                imei: '',
                sim: '',
                orgName: '',
                deviceModel: ''
            },
            pointSpeed:0,//轨迹点速度
            sliderIndex:0,//进度条
            windowInfo:{ //地图信息弹框
                lat:'',
                lng:'',
                gpsSpeed:0
            }
        }
    },
    mounted() {
        this.map = new BaiduMap()
        let that = this;
        setTimeout(() => {
            this.map.initMap('map', that.center, 15);
        });
    },
    methods: {
        /**
         * 滑块拖动
         */
        sliderChange(val){
            let index = this.trackPointArrayReturn.length * val / 100;
            this.map.setPrograssIndex(parseInt(index)-1);
        },
        /**
         * 停止播放
         */
        pauseTrack(){
            if(this.trackPointArrayReturn.length > 0) {
                this.ifPlay = false;
                this.sliderIndex = 0;
                this.pointSpeed = 0;
                this.map.rePause();
                //初始化第一个弹框
                this.windowInfo = this.trackPointArrayReturn[0];
            }
        },
        /**
         * 获取轨迹点列表
         * @param { Object } listQuery 查询数据对象
         */
        gpsSelect(){
            gpsSelect(this.listQuery).then( res => {
                if( res.code === 200 && res.data.length > 0) {
                    this.trackPointArrayReturn = res.data;
                    this.map.getAllPoints(this.trackPointArrayReturn,{},this.$refs.trackInfo);
                    this.pauseTrack();
                }
            })
        },
        /**
         * 通过imei获取设备信息
         * @param { String } imei 设备imei
         */
        getDeviceByImei(){
            getDeviceByImei({imei:this.listQuery.imei}).then(res => {
                if( res.code === 200 ) {
                    this.deviceInfo = res.data;
                }
            })
        },
        /**
         * 播放轨迹或者停止播放轨迹
         */
        playTrack(){
            if(this.trackPointArrayReturn.length > 0) {
                if( this.sliderIndex == 100) {
                    this.sliderIndex = 0;
                }
                this.ifPlay = !this.ifPlay;
                let that = this;
                function getSpeedAndPrograss(pointIndex) {
                    that.pointSpeed = that.trackPointArrayReturn[pointIndex-1].gpsSpeed;
                    that.sliderIndex = parseInt(pointIndex/that.trackPointArrayReturn.length*100);
                    that.windowInfo = that.trackPointArrayReturn[pointIndex-1];
                    if(pointIndex  == that.trackPointArrayReturn.length) {
                        that.ifPlay = false;
                    }
                }
                this.ifPlay ?  this.map.play(getSpeedAndPrograss): this.map.playPause();
            } else {
                this.$message({type:'error',message:'暂无轨迹数据'})
            }
        },
        /**
         * 速度选择下拉菜单
         */
        handleCommand(command){
            this.playSpeed = command;
            let speed = 1;
            switch (this.playSpeed) {
                case '倍数':
                    speed = 1;
                    break;
                case 'x1':
                    speed = 1;
                    break;
                case 'x2':
                    speed = 2;
                    break;
                case 'x4':
                    speed = 4;
                    break;
                case 'x8':
                    speed = 8;
                    break;
                case 'x16':
                    speed = 16;
                    break;
                default: speed = 1;;
                    break;
            }
            debugger
            this.map.setPlaySpeed(speed)
        },
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.$refs[formName].resetFields();
        },
        /** 模糊查询imei */
        querySearch(queryString, cb) {
            if (queryString.trim() == '') {
                cb([])
                return;
            }
            // deviceMatching({
            //     imei: queryString
            // }).then(res => {
            //     if (!res.data.code) {
            //         let arr = [];
            //         for (var i = 0; i < res.data.data.length; i++) { // 处理成模糊查询的数据
            //             let obj = {};
            //             obj.value = res.data.data[i];
            //             arr.push(obj);
            //         }
            //         cb(arr);
            //     } else {
            //         this.$message.error(res.data.msg);
            //     }
            // })
        },
        /**
         * 获取地图信息
         */
        getTrackList(){
            if(!this.listQuery.imei) {
                this.$message({type:'warning',message:'请输入imei'})
            }else if(!this.listQuery.startTime || !this.listQuery.endTime ) {
                this.$message({type:'warning',message:'请选择时间段'})
            }
            else if(this.listQuery.startTime > this.listQuery.endTime) {
                this.$message({type:'warning',message:'开始时间不能大于结束时间'})
            } else {
                this.gpsSelect();
                this.getDeviceByImei();
            }   
            
        },
        /** 地图切换
         * @param {String} val 选择的地图
         */
        selectChangeMap(val) {
            if(this.map.timer) {
                clearInterval(this.map.timer)
            }
            this.map.clearMap('map');
            if (val == 'google') {
                this.map = new GoogleMap();
            } else if (val == 'baidu') {
                this.map = new BaiduMap();
            }
            this.playSpeed = '倍数';
            this.map.initMap('map', this.center, 15);
            if(this.trackPointArrayReturn.length > 0) {
                this.map.getAllPoints(this.trackPointArrayReturn,{},this.$refs.trackInfo);
                this.pauseTrack();
            }
        },
        /** 切换地图类型
         *@param {String} val 地图类型 
         */
        changeMapType(val) {
            this.map.setMapType(val);
        },
        /** 切换工具箱
         * @param {String} val 工具箱选中的值
         */
        selectChange(val) {
            if (val == 'fullScreen') {
                this.map.fullScreen('map');
            } else if (val == 'ranging') {
                this.rangeDistance();
            }
        },
        /** 
         * 调用测距
         */
        rangeDistance() {
            this.map.computeLength();
        },
        /** 放大地图
         * 
         */
        zoomOut() {
            this.map.zoomOut();
        },
        /** 缩小地图
         * 
         */
        zoomIn() {
            this.map.zoomIn();
        }
    }
}
</script>
<style scoped lang="scss">
    .monitor-track {
       height: 100%;
       display: flex;
       flex-direction: column;
       .map-content {
            flex: 1;
           .map-div{
               position: relative;
               height: 100%;
               .map {
                   height: 100%;
                   border-radius: 8px;
               }
               .collapse {
                    position: absolute;
                    top:32px;
                    left: 32px;
                    width: 366px;
                    height: auto;
                    background: #FFFFFF;
                    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
                    border-radius: 8px;
                    z-index: 9;
                    .device-info {
                        width: 100%;
                        height: auto;
                        li {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .title {
                                width: 64px;
                                text-align: right;
                                font-size: 16px;
                                color: #262626;
                            }
                            .content {
                                flex: 1;
                                padding-left: 24px;
                                font-size: 16px;
                                color: #595959;
                                word-break: break-all; 
                            }
                        }
                        li + li {
                            margin-top: 16px;
                        }
                    }
               }
               .control-tool {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    left: 50%;
                    top: 32px;
                    transform: translate(-50%);
                    width: 704px;
                    padding: 0 28px;
                    box-sizing: border-box;
                    height: 60px;
                    background: #FFFFFF;
                    box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08);
                    border-radius: 8px;
                    z-index: 9;
                    .svg-icon {
                        color: #8C8C8C;
                    }
                    .svg-icon:hover {
                        color: #3270FF;
                    }
                    .icon-repaly {
                        margin-left: 24px;
                    }
                    .progress {
                        width: 350px;
                        height: 38px;
                        display:flex;
                        .slider {
                            height: 38px;
                            width: 300px;
                        }
                        .slider-tip {
                            height: 38px;
                            line-height: 38px;
                            margin-left: 12px;
                            margin-left: 14px;
                            font-size: 14px;
                            color: #595959;
                        }
                    }
                    .speed-tip {
                        margin-left: 14px;
                        font-size: 14px;
                        color: #595959;
                    }
                }
           }
       }

    }
</style>
<style lang="scss">
.monitor-track {
    .infoBox {
        img {
            display: none !important;
        }
    }
    .gm-ui-hover-effect {
        display: none !important;
    }
    .gm-style .gm-style-iw-t::after {
        display: none;
    }
    .gm-style .gm-style-iw-c {
        padding: 0;
        border-radius: 4px;
    }
    .gm-style-iw-d {
        overflow: auto !important;
    }
    .el-collapse {
        border-width:0;
        border-radius: 8px;
    }
    .el-collapse-item__header,.el-collapse-item__wrap {
        border-bottom:none;
    }
    .el-collapse-item__header {
        width: 100%;
        height: 60px;
        padding: 0 32px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #262626;
        .tip {
            display:flex; 
            width:100%;
            justify-content:space-between;
            align-items: center;
            .svg-icon {
                color: #8C8C8C;
            } 
            .icon_popup {
                transition: all .3s;
            }
        }
        .svg-icon:hover {
            color: rgb(191,191,191);
        }
        
    }
    .el-collapse-item__wrap {
        width: 100%;
        height: auto;
        background: #FFF;
        border-radius: 0px 0px 8px 8px;
        box-sizing: border-box;
        padding: 16px 32px 32px 32px;
        .el-collapse-item__content {
            padding: 0;
        }
    }
    .el-collapse-item__arrow {
        display: none;
    }
    .is-active {
        .el-collapse-item__header  {
            border-radius: 8px 8px 0px 0px;
        }
        .tip {
            transition:transform .3s linear;
            .svg-icon {
                transform: rotate(180deg);
                transition:transform .3s linear;
            }
        }
        
    }
    .control-tool{
        .speed-change {
            width: 28px;
            margin-left: 32px;
            margin-right: 32px;
            .speed {
                cursor: pointer;
                font-size: 14px;
                color: #595959;
            }
            .speed:hover {
                color: #3270FF;
            }
        }
    }
    .progress {
        .el-slider__button {
            width: 18px;
            height: 18px;
            background: #3270FF;
            border:none;
        }
        .el-slider__bar {
            background: #3270FF;
        }
    }
    .windowInfo {
        width: 180px;
        height: 86px;
        box-sizing: border-box;
        display:none;
        background-color:#FFF;
        padding: 8px 12px 4px 12px;
        box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08), 0px 0px 10px 8px rgba(0, 0, 0, 0.02), 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 4px -4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        width: auto !important;
        p {
            min-width: 130px;
            margin-bottom: 2px;
            word-wrap:break-word;
            line-height: 22px;
            display: flex;
        }
    }
}

</style>