import request from '@/utils/request'

//获取轨迹
export function gpsSelect(data) {
    return request({
        url: '/gps/select',//请求路径
        method: 'post',
        data
    });
}
//获取设备信息
export function getDeviceByImei(data) {
    return request({
        url: '/base/device/getDeviceByImei',//请求路径
        method: 'get',
        params:data
    });
}



